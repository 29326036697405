import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";
import { toast, type ToastOptions } from "vue3-toastify";

import useAppInitData, { type InitDataResponseData } from "./useAppInitData";
import useSockets from "./useSockets";

export type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};
export type NotificationMessage = NonNullable<
	NonNullable<InitDataResponseData>["notificationCenter"]
>["messages"][number];

export type NotificationMessagePayload = {
	id: number;
	needSendStatusToServer: boolean;
	shouldOpenLink: boolean;
};

const MESSAGE_TYPE_ORDER = {
	offer: 0,
	system: 1,
	system_offer: 2
} as const;

const audioListDictionary = new Map([
	["default", "/uploads/notification_center/audio/notification.ogg"],
	["deposit", "/uploads/notification_center/audio/deposit.ogg"],
	["bonusCredit", "/uploads/notification_center/audio/bonus_credit.ogg"],
	["withdrawal", "/uploads/notification_center/audio/withdrawal.ogg"]
]);

const DEFAULT_TOAST_OPTIONS = {
	theme: toast.THEME.LIGHT,
	position: toast.POSITION.BOTTOM_RIGHT,
	transition: toast.TRANSITIONS.SLIDE,
	autoClose: 10000
};

const useNotificationCenter = <K extends string>({
	toastOptions = {},
	isOpen
}: {
	isOpen: (name: K) => boolean;
	toastOptions?: ToastOptions;
}) => {
	const { data: appInitData } = useAppInitData();
	const { add } = useSockets();
	const { isIos, isSafari, isMacOS } = useDevice();

	const showNotificationPopup = useState("showNotificationPopup", () => false);

	const notifications = computed(() => appInitData.value?.notificationCenter?.messages ?? []);
	const sortedNotifications = computed(() => {
		const messages = [...notifications.value];
		return messages.sort(
			(a, b) => dayjs(b.createdAt).diff(a.createdAt) || MESSAGE_TYPE_ORDER[a.type] - MESSAGE_TYPE_ORDER[b.type] || 0
		);
	});

	const unreadNotifications = computed(() => sortedNotifications.value.filter((item) => !item.isReaded));
	const readNotifications = computed(() => sortedNotifications.value.filter((item) => item.isReaded));
	const isActiveAnimation = computed(
		() => unreadNotifications.value.length && isClient && !window?.$cash?.$store?.getters?.["cash/isOpen"]
	);

	const emailConfirmed = computed(() => appInitData.value?.emailConfirmed);

	const setNotificationsStatusAsRead = (ids: number[]) => {
		const notificationsMap = new Map(notifications.value.map((item: NotificationMessage) => [item.ncMessageId, item]));
		ids.forEach((id) => {
			const notification = notificationsMap.get(id);
			if (notification) {
				notification.isReaded = true;
				notificationsMap.set(id, notification);
			}
		});
	};

	const updateNotificationsStatusAsRead = async (ids: number[]) =>
		await apiClient({
			path: "/rest/notification-center/messages/read/",
			method: "post",
			parameters: {
				body: { ids }
			},
			options: {
				onResponse: ({ response }) => {
					if (response._data.success) {
						setNotificationsStatusAsRead(ids);
					}
				}
			}
		});

	const openNotificationLink = (link?: string) => {
		if (link) {
			showNotificationPopup.value = false;
			navigateTo(link, { external: /^https?/.test(link) });
		}
	};

	const handleClickNotificationMessage = ({
		id,
		needSendStatusToServer,
		shouldOpenLink
	}: NotificationMessagePayload) => {
		const notificationsMap = new Map(notifications.value.map((item: NotificationMessage) => [item.ncMessageId, item]));
		const notification = notificationsMap.get(id);
		if (!notification) {
			return;
		}
		if (shouldOpenLink && notification.isReaded) {
			openNotificationLink(notification.link);
			return;
		}

		if (needSendStatusToServer) {
			updateNotificationsStatusAsRead([id]).finally(() => {
				if (shouldOpenLink) {
					openNotificationLink(notification.link);
				}
			});
			return;
		}

		setNotificationsStatusAsRead([id]);
		if (shouldOpenLink) {
			openNotificationLink(notification.link);
		}
	};

	const showPushNotification = ({ message, title, image, link, ncMessageId, systemAlias }: NotificationMessage) => {
		if (!isIos || !(isMacOS && isSafari)) {
			const audio = new Audio(audioListDictionary.get(systemAlias) ?? audioListDictionary.get("default"));
			audio.addEventListener("canplaythrough", () => {
				audio.play();
			});
		}

		const handleCloseToast = (event: MouseEvent) => {
			event.stopPropagation();
			if (!link) {
				updateNotificationsStatusAsRead([ncMessageId]);
			}
		};
		toast.success(
			`<p class="notification-title">${title}</p>
			<p class="notification-text">${message}</p>`,
			{
				containerId: !emailConfirmed.value ? "notification-center-container-email" : "notification-center-container",
				icon: h("img", { class: "notification-img", src: image.replace("http:", "https:") }),
				dangerouslyHTMLString: true,
				...DEFAULT_TOAST_OPTIONS,
				...toastOptions,
				onClick: (event: MouseEvent) => {
					handleCloseToast(event);
					handleClickNotificationMessage({
						id: ncMessageId,
						shouldOpenLink: true,
						needSendStatusToServer: Boolean(link)
					});
				}
			}
		);
	};

	add("nc", ({ action, data }: EventData<NotificationMessage>) => {
		if (action === "ncMessageNew") {
			if (
				!window?.$cash?.$store?.getters?.["cash/isOpen"] &&
				!isOpen("LazyOModalGame" as K) &&
				!showNotificationPopup.value
			) {
				showPushNotification(data);
			}

			if (appInitData.value?.notificationCenter) {
				const existingIndex = appInitData.value.notificationCenter.messages.findIndex(
					(item) => item.ncMessageId === data.ncMessageId
				);

				if (existingIndex === -1) {
					appInitData.value.notificationCenter.messages.push(data);
					return;
				}

				appInitData.value.notificationCenter.messages[existingIndex] = data;
			}
		}
	});

	const openPopup = () => {
		showNotificationPopup.value = true;
		toast.clearAll("notification-center-container");
	};

	return {
		unreadNotifications,
		readNotifications,
		isActiveAnimation,
		showNotificationPopup,
		handleClickNotificationMessage,
		setNotificationsStatusAsRead,
		updateNotificationsStatusAsRead,
		openPopup
	};
};

export default useNotificationCenter;
